document.addEventListener("turbolinks:load", function () {
    const infos = document.querySelector('#infos');
    const condenses = document.querySelector('#condenses');
    const formulaire = document.querySelector('#formulaire');
    const selection = document.querySelector('#appelant_id');
    
    function okinfos() {
        const appelantNo = okinfo.getAttribute('data')
            fetch(`/appelants/${appelantNo}`)
            .then(response => response.json())
            .then((data) => {
                formulaire.innerHTML = "<div id='infos' class='list-group-item'></div>"
                const infos = document.querySelector('#infos');
                infos.innerHTML = "";
                infos.insertAdjacentHTML("beforeend", `<p>Contact : ${data.prenom} ${data.nom}</p>`);
                infos.insertAdjacentHTML("beforeend", `<p>Téléphone : ${data.telephone}<p>`);
                infos.insertAdjacentHTML("beforeend", `<p>Email : ${data.email}<p>`);
                infos.insertAdjacentHTML("beforeend", `<p>Année de naissance : ${data.annee_de_naissance}<p>`);
                infos.insertAdjacentHTML("beforeend", `<p>Adresse de naissance : ${data.cpnaissance}<p>`);
                infos.insertAdjacentHTML("beforeend", `<p>Adresse de residence : ${data.cpresidence}<p>`);

                condenses.innerHTML = "";
                data.infos_appels.forEach(element => {
                    const lien = `<div class="list-group-item">
                                        <strong> #</strong> ${element.condense}<br>
                                        <strong>Motif :</strong> ${element.motif}<br>
                                        <strong>Résumé :</strong> ${element.resume}<br>
                                        <strong>Commentaires :</strong> ${element.commentaires}
                                    </div>`
                    condenses.insertAdjacentHTML("beforeend", lien);
                });

                
            });
    }
    const okinfo = document.querySelector('#okinfos')
    if (okinfo) {
        console.log(okinfo);
      okinfo.addEventListener("click", okinfos, false)  
    }
    
});