const { event } = require("jquery");
document.addEventListener("turbolinks:load", function () {
    const barre = document.querySelector('#champ_de_recherche');
    const choix = document.querySelector('#choix')
    const lien_nouveau = `<li> <a href="/appelants/new"> Créer un nouvel appelant </a> </li>`
    const lien_inconnu = `<li> <a href="/appelants/1/appels/new"> Inconnu ou personne souhaitant rester dans l'anonymat </a> </li>`

    if (barre) {
        recherche();
    }


    function recherche() {
        barre.addEventListener("keyup", (event) => {
            fetch(`/appelants/?q=${event.currentTarget.value}`)
                .then(response => response.json())
                .then((data) => {
                    console.log(data);
                    affiche_choix(data);
                });
        });
    }

    function affiche_choix(data) {
        choix.innerHTML = "";
        choix.insertAdjacentHTML("beforeend", lien_inconnu);
        if (data && barre.value) {
            data.forEach(element => {
                const lien = `<li> <a href="/appelants/${element.id}/appels/new"> ${element.prenom} - ${element.nom} - ${element.telephone} </a> </li>`
                choix.insertAdjacentHTML("beforeend", lien);
            });
            
            choix.insertAdjacentHTML("beforeend", lien_nouveau);
        }
    };

});